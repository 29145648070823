import { render, staticRenderFns } from "./ToeicSpeakingScoreComponent.vue?vue&type=template&id=22074c75&scoped=true&"
import script from "./ToeicSpeakingScoreComponent.vue?vue&type=script&lang=js&"
export * from "./ToeicSpeakingScoreComponent.vue?vue&type=script&lang=js&"
import style0 from "./ToeicSpeakingScoreComponent.vue?vue&type=style&index=0&id=22074c75&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22074c75",
  null
  
)

export default component.exports